import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { INode, SlideTogglerItems } from 'src/app/lib/interfaces/interface';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { selectPartnerIsLteDemo } from 'src/app/store/customer/customer.selectors';
import { selectNodes } from 'src/app/store/polling/polling.selector';

@Component({
  selector: 'networkperformance',
  templateUrl: './networkperformance.component.html',
  styleUrls: ['./networkperformance.component.scss']
})
export class NetworkperformanceComponent implements OnInit, AfterViewInit {
  @Input()
  status: any;

  @Input()
  selected: any;

  @Input()
  alerts: any;

  @Input()
  overallScore: number = 0;

  // Demo
  isLteDemo: boolean = false;
  accessScore: number = 100;
  modalOpen: boolean = false;
  modalType: string = null;
  dateTimeNow: string = new Date().toISOString();
  gatewayMac: string = '';
  publicIp: string = '';

  mockDnsAlertJson = {};
  mockDhcpAlertJson = {};
  interval = null;

  @Output()
  section: EventEmitter<string> = new EventEmitter<string>();

  @ViewChildren('circle') circles: QueryList<ElementRef>;
  @ViewChildren('accessCircle') accessCircles: QueryList<ElementRef>;
  @ViewChild('center') center: ElementRef;
  @ViewChild('diagram') diagram: ElementRef;

  updateSubscription: any;
  permissions: any;
  nodesSubscription: Subscription;

  // Demo
  toggleOptions: SlideTogglerItems<'home' | 'access'> = [
    {
      value: 'home',
      translation: 'health.networkStatus.home',
      selected: true
    },
    {
      value: 'access',
      translation: 'health.networkStatus.accessNetwork',
      selected: false
    }
  ];
  mode: 'home' | 'access' = 'home';

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (window.innerWidth > 1024) {
      const angle = 360 / this.circles.length;
      const offset = this.diagram.nativeElement.offsetWidth;
      const size = offset / 4;

      this.diagram.nativeElement.style.height = offset + 'px';
      this.center.nativeElement.style.width = size + 'px';
      this.center.nativeElement.style.height = size + 'px';

      this.circles.forEach((circle: ElementRef, index: number) => {
        circle.nativeElement.style.height = size + 'px';
        circle.nativeElement.style.width = size + 'px';

        const y = Math.sin(angle * index * Math.PI) * (offset / 3);
        const x = Math.cos(angle * index * Math.PI) * (offset / 3);

        circle.nativeElement.style.top = y + 'px';
        circle.nativeElement.style.left = x + 'px';
      });

      this.accessCircles.forEach((accessCircle: ElementRef, index: number) => {
        accessCircle.nativeElement.style.height = size + 'px';
        accessCircle.nativeElement.style.width = size + 'px';

        const y = Math.sin(angle * 2 * index * Math.PI) * (offset / 3);
        const x = Math.cos(angle * 2 * index * Math.PI) * (offset / 3);

        accessCircle.nativeElement.style.top = y + 'px';
        accessCircle.nativeElement.style.left = x + 'px';
      });
    } else {
      this.diagram.nativeElement.style.height = '';
      this.center.nativeElement.style.width = '';
      this.center.nativeElement.style.height = '';

      this.circles.forEach((circle: ElementRef) => {
        circle.nativeElement.style.height = '';
        circle.nativeElement.style.width = '';
        circle.nativeElement.style.top = '';
        circle.nativeElement.style.left = '';
      });

      this.accessCircles.forEach((accessCircle: ElementRef) => {
        accessCircle.nativeElement.style.height = '';
        accessCircle.nativeElement.style.width = '';
        accessCircle.nativeElement.style.top = '';
        accessCircle.nativeElement.style.left = '';
      });
    }
  }

  constructor(private plume: PlumeService, private store: Store) {}

  ngOnInit(): void {
    this.permissions = this.plume.getPermissions();

    this.nodesSubscription = this.store.select(selectNodes).subscribe((nodes) => {
      if (nodes) {
        const firstGatewayNode: INode = nodes.filter((node: INode) => node.leafToRoot.length === 0)?.[0];
        this.publicIp = firstGatewayNode?.publicIp || '';
        this.gatewayMac = firstGatewayNode?.mac || '';
      }
    });

    this.store.select(selectPartnerIsLteDemo).subscribe((isLteDemo) => {
      if (isLteDemo) {
        this.isLteDemo = true;
        this.startInterval();
        this.onResize();
      }
    });
  }

  // Demo
  async loadDemoData() {
    const dns = await fetch('https://starhub-dhcp-dns-logs.s3.ap-south-1.amazonaws.com/dns.json', {
      headers: {
        'Cache-control': 'private, no-store, max-age=0'
      }
    });
    const dhcp = await fetch('https://starhub-dhcp-dns-logs.s3.ap-south-1.amazonaws.com/dhcp.json', {
      headers: {
        'Cache-control': 'private, no-store, max-age=0'
      }
    });

    const dnsJson = await dns.json();
    const dhcpJson = await dhcp.json();

    this.mockDnsAlertJson = dnsJson;
    this.mockDhcpAlertJson = dhcpJson;

    const dnsScore = dnsJson?.status === 'OK' ? 100 : 0;
    const dhcpScore = dhcpJson?.status === 'OK' ? 100 : 0;

    this.alerts = {
      ...this.alerts,
      dnsAlert: dnsScore <= 50,
      dnsWarning: dnsScore < 100 && dnsScore > 50,
      dhcpAlert: dhcpScore <= 50,
      dhcpWarning: dhcpScore < 100 && dhcpScore > 50
    };

    this.accessScore = 100;
    if (this.alerts.dnsAlert) {
      this.accessScore -= 50;
    } else {
      if (this.alerts.dnsWarning) {
        this.accessScore -= 25;
      }
    }
    if (this.alerts.dhcpAlert) {
      this.accessScore -= 50;
    } else {
      if (this.alerts.dhcpWarning) {
        this.accessScore -= 25;
      }
    }
  }

  startInterval() {
    window.dispatchEvent(new Event('resize'));
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);

    this.loadDemoData();

    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(async () => {
      await this.loadDemoData();
    }, 30_000);
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  // Demo
  openModal(modalType: string): void {
    this.modalOpen = true;
    this.modalType = modalType;
  }

  // Demo
  closeModal(): void {
    this.modalOpen = false;
    this.modalType = null;
  }

  select(type: string): void {
    this.section.emit(type);
  }

  // Demo
  setMode(value: 'home' | 'access'): void {
    this.mode = value;
    this.section.emit();
  }

  anyAlerts(): boolean {
    if (
      (this.alerts.extenderspeed && this.alerts.extenderspeed.length > 0) ||
      (this.alerts.extenderspeed && this.alerts.networkspeed.length > 0) ||
      (this.alerts.deviation && this.alerts.deviation.status === true)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
